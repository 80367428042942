"use strict";

import { ModuleClass } from '../../js/DefaultModule';
var Sticky = require('../../js/Sticky');

const MODULE_NAME = 'navigation-mobile';

const Module = {

    $element: null,
    activeClass: 'active',

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        // this.sticky();

        // Set body fixed when element is show.bs.collapse
        this.bodyFixedOnBsCollapse();

        // When user clicks on mobile links, closes navigation-mobile
        this.onClickClose();
    },

    // ______________________________________________________________________

    /**
     * Init sticky navbar
     */
    sticky() {

        // Pass ID as selector to Sticky, that accept selectors only
        new Sticky('#' + this.module.$element.getAttribute('id'));
    },

    // ______________________________________________________________________

    /**
     * When navigation change status, send event to body
     */
    bodyFixedOnBsCollapse() {

        // send event to body when navigation mobile is visible
        this.module.$element.addEventListener('show.bs.collapse', (e) => {
            let event = new Event('navigation-mobile-visible');
            document.body.dispatchEvent(event);
        });

        // send event to body when navigation mobile is collapsed
        this.module.$element.addEventListener('hidden.bs.collapse', (e) => {
            let event = new Event('navigation-mobile-hidden');
            document.body.dispatchEvent(event);
        });
    },
    // ______________________________________________________________________
    
    onClickClose() {

        let $blockNavbar = document.querySelector('.block-navbar');
        let $navigationMobile = document.querySelector('.block-navigation-mobile');
        
        // block-navbar: close clicking on x
        $blockNavbar.addEventListener('click',(e) => {

            // event delegation
            if(e.target.nodeName === 'A' || e.target.closest('A') ) {

                // fix: before all send event to body because it need position relative on it
                let eventBody = new Event('navigation-mobile-hidden');
                document.body.dispatchEvent(eventBody);

                // collapse menu
                /*
                let eventNav = new Event('collapse');
                $navigationMobile.dispatchEvent(eventNav);
                */
                var collapseInstance = bootstrap.Collapse.getOrCreateInstance(this.module.$element,{toggle:false});
                collapseInstance.hide();
            }
        });
        
        // close when click on link inside .block-navigation-mobile
        $navigationMobile.addEventListener('click',(e) => {

            // event delegation
            if(e.target.nodeName === 'A') {

                // fix: before all send event to body because it need position relative on it
                let eventBody = new Event('navigation-mobile-hidden');
                document.body.dispatchEvent(eventBody);

                // collapse menu
                /*
                let eventNav = new Event('collapse');
                $navigationMobile.dispatchEvent(eventNav);
                */
                var collapseInstance = bootstrap.Collapse.getOrCreateInstance(this.module.$element);
                collapseInstance.hide();
            }
        });
        
    }

    // ______________________________________________________________________

};

export default Module;
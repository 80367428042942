"use strict";

import {ModuleClass} from '../../js/DefaultModule';
var Sticky = require('../../js/Sticky');
import {EventRelation} from '../../js/EventRelation';
import { prefetchable } from "webpack-prefetcher";

const MODULE_NAME = 'navbar';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.sticky();

        // click events
        this.navbarClickEvents();
    },

    // ______________________________________________________________________

    /**
     * Lazy Load EventRelation (here is used on desktop only) only after mouseenter
     * @param {*} $element 
     */
     navbarClickEvents() {

        const that = this;
        const $navbar = this.module.$element.querySelector('.navbar-nav');

        if($navbar) {
            // on mouseenter, lazy load EventRelation
            $navbar.addEventListener('mouseenter', that.lazyLoadEventRelation);

            // pass this to event
            $navbar.that = this;
        }
    },

    // ______________________________________________________________________

    /**
     * Lazy Event: load EventRelation
     * @param {} e 
     */
    lazyLoadEventRelation(e) {

        let that = e.currentTarget.that;
        let $navbar = e.currentTarget;

        // dispatch event to navbar, to load other deps
        let eventDeps = new Event('lazy-load-EventRelation');
        $navbar.dispatchEvent(eventDeps);

        // Load Module EventRelation and run it
        let ModuleLoad = prefetchable(() => import('../../js/EventRelation'));
        ModuleLoad.load().then( (Module) => {
            
            Module.EventRelation.createEvent({
                event: 'click',
                $parentElement: $navbar,
                itemSelector: 'a',
                hrefAttribute: 'href',
                relAttribute: 'rel'
            });

            // load once
            $navbar.removeEventListener('mouseenter', that.lazyLoadEventRelation);

        });

    },

    // ______________________________________________________________________

    /**
     * Init sticky navbar
     */
     sticky() {

        // Pass ID as selector to Sticky, that accept selectors only
        new Sticky('#' + this.module.$element.getAttribute('id'));

        // event sticky
        this.module.$element.addEventListener('sticky',(e) => {
            document.body.classList.add('sticky');
        });

        // event unsticky
        this.module.$element.addEventListener('unsticky',(e) => {
            document.body.classList.remove('sticky');
        });
    },

};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'comments';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.init_reply_buttons();
    },

    // ______________________________________________________________________

    /**
     * Listener: .reply_button
     * Click on reply buttons
     */
    init_reply_buttons() {

        // Form
        var $form = this.module.$element.querySelector('.comment-respond');
        var $comment_parent = $form.querySelector('[name=comment_parent');

        // Listener
        this.module.$element.addEventListener('click',(e) => {
            if(e.target.classList.contains('reply_button')) {
                // e.preventDefault();

                // get ID
                let ID = e.target.getAttribute('data-ID');

                // change comment_parent field
                $comment_parent.value = ID;

                // detach form
                $form.parentNode.removeChild($form);

                // reattach form
                e.target.closest('.container-comment').querySelector('.container-comment-content').append($form);

            }
        });

    }

    // ______________________________________________________________________

};

export default Module;
"use strict";

import { prefetchable } from "webpack-prefetcher";
import LazyLoad from 'vanilla-lazyload';

/**
 * Load modules printed in current page
 * 
 * Each block print inline:
 * 
 * <script>
 *   window.blocks = window.blocks || [];
 *   blocks.push({
 *     'name': 'cover',
 *     'id': 'block_61151674d3c68',
 *     });
 * </script>
 * <div class="block-{moduleName}" data-block="{moduleName}"> 
 *   <!-- block content -->
 * </div>
 */
const Module = {

    requiredModules: {}, // Modules to be loaded
    loadedModules: [], // All loaded modules are stored here

    // ______________________________________________________________________

    /**
     * Prefetch a module by known name
     * @param {*} name 
     */
    prefetchKnown(name) {
        this.requiredModules[name] = {
            config: {
                name: name
            },
            module: prefetchable(() => import(
                /* webpackPrefetch: true */
                /* webpackMode: "eager" */
                './' + name)
            )
        }
    },

    // ______________________________________________________________________

    /**
     * Prefetch module using "not known" name, by a list generated runtime for each page.
     * 
     * dynamicModules are modules printed in the current page.
     * Prefetch only blocks declared in window.blocks array
     */
    prefetchDynamic() {
        window.blocks.forEach((block) => {
            this.requiredModules[block.name] = {
                config: block,
                module: prefetchable(() => import(
                    /* webpackMode: "lazy" */
                    '../blocks/' + block.name)
                ) // prefetch /blocks/moduleName/index.js
            }
        });
    },

    // ______________________________________________________________________

    /**
     * Lazy load all required modules. 
     * Note: the modules are prefetched by prefetch() and prefetchDynamic() 
     */
    loadModules() {
        var that = this;

        // Cycle all required modules
        Object.keys(this.requiredModules).forEach(function (moduleName) {
            let requiredModule = that.requiredModules[moduleName];

            if (requiredModule.config.lazy === false) {
                // instant load current module
                that.instantLoadModule(requiredModule);
            } else {
                // lazyLoad current module
                that.lazyLoadModule(requiredModule);
            }
        });
    },

    // ______________________________________________________________________

    /**
     * Instant load module (no lazy, maybe because it was hidden and no Observable apply)
     * @param {*} moduleName 
     * @returns 
     */
    instantLoadModule(requiredModule) {
        var that = this;
        let moduleName = requiredModule.config.name

        // inizializza il modulo per nome
        that.requiredModules[moduleName].module.load().then((Module) => {
            let $element = document.querySelector('[data-block-' + moduleName + ']') // set element, max one
            Module.default.init($element);
        });
    },

    // ______________________________________________________________________

    /**
     * Set lazy on given module.
     * Every time the element enter the viewport, it prefetched.load() the module, and then unobserve it.
     * @param {} moduleName 
     * @returns 
     */
    lazyLoadModule(requiredModule) {
        var that = this;
        let moduleName = requiredModule.config.name

        /*
        // FEATURE: Force preload of block-style-moduleName
        // Unused cause of huge thread work
        const $module_s = document.querySelectorAll('[data-block-' + moduleName + ']:not([data-preloaded=true])'); // all blocks not marked as data-preloaded
        $module_s.forEach(($module) => {

            // if has data-style, then set preload on it
            if($module.hasAttribute('data-style-' + moduleName)) {
                var linkElem = document.createElement("link");
                var preloadHref = template_directory_uri + '/assets/css/' + hash + '-blocks/' + moduleName + '.css';

                linkElem.setAttribute("rel","preload");
                linkElem.setAttribute("href",preloadHref);
                linkElem.setAttribute("as","image");
                document.head.appendChild(linkElem);
            }

            $module.setAttribute('data-preloaded',true); // mark as preloaded
        });
        */

        // Init lazyLoad for current module, by moduleName, identified by moduleSelector
        return new LazyLoad({
            elements_selector: '[data-block-' + moduleName + ']', // every 
            unobserve_entered: true, // avoid multiple executions on every enter            
            class_entered: 'entered',
            threshold: 10,
            callback_enter: function ($element) {

                // se il modulo non è ancora stato caricato, lo importa
                if (!that.loadedModules.includes(moduleName)) {

                    // inizializza il modulo per nome
                    that.requiredModules[moduleName].module.load().then((Module) => {
                        Module.default.init($element);
                    });

                } // does include?
            } // callback enter
        }); // new lazy

    },

    // ______________________________________________________________________


};

export { Module as Modules };
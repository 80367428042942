"use strict";

import {ModuleClass} from '../../js/DefaultModule';
var Sticky = require('../../js/Sticky');
import { prefetchable } from "webpack-prefetcher";

const MODULE_NAME = 'site-header';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.sticky();
    },

    // ______________________________________________________________________

    /**
     * Init sticky navbar
     */
     sticky() {

        // if(this.module.$element.classList.contains('position-sticky')) {
            let id = this.module.$element.getAttribute('id');

            // Pass ID as selector to Sticky, that accept selectors only
            new Sticky('#' + id);

            // event sticky
            this.module.$element.addEventListener('sticky',(e) => {
                document.body.classList.add('sticky');
            });

            // event unsticky
            this.module.$element.addEventListener('unsticky',(e) => {
                document.body.classList.remove('sticky');
            });
        // }


    },

    // ______________________________________________________________________

};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';
import { prefetchable } from "webpack-prefetcher";

const MODULE_NAME = 'cpt-card';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        var that = this;

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.propagatePermalinkToAnchestorColumn();

    },

    // ______________________________________________________________________

    propagatePermalinkToAnchestorColumn() {

        // Get all permalinks
        var $permalink_s = this.module.$element.querySelectorAll('.permalink');
        $permalink_s.forEach(($permalink) => {

            // url
            let url = $permalink.getAttribute('href');

            // each permalink propagate click action to its anchestor .col
            let $col = $permalink.closest('article');

            // set event
            $col.addEventListener('click',(e) => {
                window.location = url;
            });

        });
    }

    // ______________________________________________________________________

};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';
var Sticky = require('../../js/Sticky');
import {EventRelation} from '../../js/EventRelation';

const MODULE_NAME = 'subnavbar';

const Module = {

    $element: null,
    activeClass: 'active',

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.sticky();

        // init related click events
        this.relatedClickEvents();
    },

    // ______________________________________________________________________

    /**
     * Init sticky navbar
     */
     sticky() {

        // Pass ID as selector to Sticky, that accept selectors only
        new Sticky('#' + this.module.$element.getAttribute('id'));
    },

    // ______________________________________________________________________

    /**
     * Intercept clickRelation events
     * This type of event toggles subnavbar childs.
     */
    relatedClickEvents() {

        var that = this;

        // Subnavbar will toggle childs
        EventRelation.addListener({
            $element: this.module.$element,
            callback: function(e) {
                that.toggle(e.detail)
            },
            dismissCallback: function(e) {
                that.dismissAll(e.detail)
            }

        });

    },

    // ______________________________________________________________________

    /**
     * Dismiss all elements
     */
    dismissAll(detail) {
        const that = this;

        const $widget_s = this.module.$element.querySelectorAll('.widget');

        // dismiss all sub elements
        $widget_s.forEach(($widget) => {
            that._dismissElement($widget);
        });

        // also dismiss module
        this._dismissElement(this.module.$element);

        // destroy all initiators
        document.querySelectorAll('[data-event-relation-active="true"]').forEach(($el) => {
            $el.setAttribute('data-event-relation-active',"false");
        });

        // mark body as subnavbar dismissed
        document.body.setAttribute('data-relation-subnavbar','dismissed');
    },

    // ______________________________________________________________________

    /**
     * Toggle element
     * 
     * @param {} detail 
     */
    toggle(detail) {

        const that = this;

        const $current = this.module.$element.querySelector(detail.href);
        let $currentWidget = $current.parentNode;
        const $widget_s = this.module.$element.querySelectorAll('.widget');
        const $clickedElement = detail.initiator;

        // destroy all initiators, not current clicked one
        document.querySelectorAll('[data-event-relation-active="true"]').forEach(($el) => {
            if(detail.initiator != $el) {
                $el.setAttribute('data-event-relation-active',"false");
            }
        });

        // remove .active from all widget except current one
        $widget_s.forEach(($widget) => {
            
            let $target = $widget.querySelector('div'); // get first div inside
            let $trigger = $widget.querySelector('div')

            // except current one remove
            if($current.getAttribute('id') !== $target.getAttribute('id') ) {
                that._dismissElement($widget);
            }

            // only on current one toggle
            if($current.getAttribute('id') === $target.getAttribute('id') ) {
                that._toggleElement($widget);
                that._toggleInitiator($clickedElement);
            }

        })
    },

    // ______________________________________________________________________

    _toggleInitiator($clickedElement) {

        let dataEventRelationActive = $clickedElement.getAttribute('data-event-relation-active');
        
        if(dataEventRelationActive == "true") {
            // DISABLING
            $clickedElement.setAttribute('data-event-relation-active',"false");
        } else {
            // ENABLING
            $clickedElement.setAttribute('data-event-relation-active',"true");

        }
    },

    // ______________________________________________________________________

    /**
     * Toggle module and sub element
     * @param {*} $element 
     */
    _toggleElement($element) {
        if($element.classList.contains(this.activeClass)) {

            // DISABLING
            // dismiss both element and container
            this._dismissElement($element);
            this._dismissElement(this.module.$element);

            // mark body as subnavbar dismissed
            document.body.setAttribute('data-relation-subnavbar','dismissed');

        } else {
            
            // ENABLING
            // enable both element and container
            this._activateElement($element);
            this._activateElement(this.module.$element);

            // mark body as subnavbar open
            document.body.setAttribute('data-relation-subnavbar','active');

        }
    },

    // ______________________________________________________________________

    /**
     * Set active class to given element
     * @param {} $element 
     */
    _activateElement($element) {
        $element.classList.add(this.activeClass);
    },

    // ______________________________________________________________________

    /**
     * Remove active class to given element
     * @param {*} $element 
     */
    _dismissElement($element) {
        $element.classList.remove(this.activeClass);
    }

    // ______________________________________________________________________

};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'sticky-cta';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.onClickDisposeCta();
    },

    // ______________________________________________________________________

    onClickDisposeCta() {
        let $cta = this.module.$element.addEventListener('click', (e) => {
            this.module.$element.classList.add('disposed');
        });
    }

    // ______________________________________________________________________

};

export default Module;
"use strict";

import {Modules} from "./js/Modules";

// _______________

/**
 * Prefetch known modules
 * located in /js/{moduleName}.js
 */
Modules.prefetchKnown('UI');

/**
 * Prefetch dynamic modules
 * located in /blocks/moduleName/index.js
 * 
 * Each module is declared in blocks array, printed before main.js in footinclude
 * 
 * window.blocks = window.blocks || [];
 * blocks.push({
 *   'name': 'moduleName',
 *   'id': 'block_<?php echo getWebpackMeta()['hash']; ?>'
 * });
 */
Modules.prefetchDynamic();

// Load all modules (with rules)
Modules.loadModules();

// _______________

/**
 * Set all files in wp-theme and wp-theme-child in watch
 * 
 * Rif: https://stackoverflow.com/questions/29421409/how-to-load-all-files-in-a-directory-using-webpack-without-require-statements
 * @param {*} require 
 */
/*
    function requireAll(require) {
        require.keys().forEach(require);
    }
    requireAll(require.context('./wp-theme', true, /\.*$/));
    requireAll(require.context('./wp-theme-child', true, /\.*$/));
*/
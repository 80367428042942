"use strict";

import { prefetchable } from "webpack-prefetcher";

// ______________________________________________________________________

import 'material-icons';
import Utils from './Utils';

const simpleIcons = require('simple-icons');

// ______________________________________________________________________

// Load icons in current view

// All icons
let $icon_s = document.querySelectorAll('[data-icon]');
$icon_s.forEach(($icon) => {

    // get icon name
    let icon_name = $icon.getAttribute('data-icon');

    // get icon
    const icon = simpleIcons.Get(icon_name);

    if(icon && icon != undefined) {
        
        // inner text becomes title
        $icon.setAttribute('title',$icon.innerText);

        // include icon and rewrite old content
        $icon.innerHTML = icon.svg;
    }
});

// ______________________________________________________________________

// Bootstrap imports
import { Collapse } from 'bootstrap';

import LazyLoad from "vanilla-lazyload";

const Module = {

    // ______________________________________________________________________

    init() {
        // mark if device is touch
        this.waitInteractive();
        this.checkTouchDevice();
        this.bootstrapCollapse();
        this.lazyLoad();

        // attach feature: enable collapse
        // this.lazyEnableCollapse();
    },

    // ______________________________________________________________________

    /**
     * Wait status interactive and append class .interactive to body
     * 
     * .wait-interactive { opacity;0 }
     * body.interactive .wait-interactive { opacity:1 }
     */
    waitInteractive() {
        Utils.ready(() => {
            if (document.readyState == "interactive") {
              document.body.classList.add('interactive');
            }
        });

        // fallback
        setTimeout("document.body.classList.add('interactive');", 2000);
    },

    // ______________________________________________________________________

    /**
     * If device has touch, set it in body
     */
    checkTouchDevice() {
        let isTouchDevice = 'ontouchstart' in window;
        isTouchDevice && document.body.classList.add('is-touch-device');
    },

    // ______________________________________________________________________

    /**
     * Enable bootstrap collapse using a lazy approach
     */
    /*
    lazyEnableCollapse() {

        let that = this;

        // on hamburger hover
        this.collapseModule = prefetchable(() => import('bootstrap/js/src/collapse'));

        let $collapsibleTrigger_s = document.querySelectorAll('[data-bs-toggle="collapse"]')
        $collapsibleTrigger_s.forEach(($collapsibleTrigger) => {

            // add event loader to collapsibleTrigger
            $collapsibleTrigger.addEventListener('click', that.bindEventLoad);

            $collapsibleTrigger.that = that;
        });
    },
    */

    // ______________________________________________________________________

    // Event handler
    /*
    bindEventLoad(e) {

        // retrieve that
        let that = e.currentTarget.that;

        // Load collapse
        const $collapsibleTrigger = e.currentTarget;
        const href = $collapsibleTrigger.getAttribute('href');
        const $collapsible = document.querySelector(href);

        // check if Collapse loaded
        if ($collapsible.getAttribute('data-loaded-collapse') !== "true") {

            that.collapseModule.load().then((Collapse) => {

                // Detach event
                $collapsibleTrigger.removeEventListener('click',that.bindEventLoad);

                // Enable collapsible
                var collapseInstance = new Collapse.default($collapsible, { toggle: true });

                // add event close on collapsible
                $collapsible.addEventListener('collapse',(e) => {
                    console.log(collapseInstance);
                    collapseInstance.hide();
                });

                return collapseInstance;
            });
        }

    },  // end event handler
    */

    /**
     * Enable bootstrap 5 Collapse on elements identified by .collapse
     */
    bootstrapCollapse() {
        var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
        collapseElementList.map(function (collapseEl) {
          return new Collapse(collapseEl, {
              // hide:true
              toggle:false
          })
        });
    },

    // ______________________________________________________________________

    /**
     * Lazy loads default configuration (all elements identified by .lazy selector)
     */
    lazyLoad() {

        new LazyLoad({
            elements_selector: '.lazy',
            unobserve_entered: true, // <- Avoid executing the function multiple times,
            // cancel_on_exit:true,
            use_native: false,
            threshold:300,
            // callback_enter: function ($element) { }
        });

    },

    // ______________________________________________________________________

    /**
     * Update css custom properties on window resize.
     * --vh
     * --vw
     * 
     * This is pretty usefull on mobile device, where viewport is dynamic on scroll
     */
    viewport() {
        // AGGIORNA IL VIEWPORT

        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth * 0.01;

        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            let vw = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            document.documentElement.style.setProperty('--vw', `${vw}px`);
        });
    },

    // ______________________________________________________________________

};

export default Module;
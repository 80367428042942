"use strict";

/**
 * Common methods for all block modules
 */
class DefaultModule {

    moduleName = null;

    // called by super({...args})
    constructor(args) {

        // args becomes props
        for( var key in args) {
            this[key] = args[key];
        };
    }

    /**
     * Import css if necessary.
     * 
     * if attribute data-style is present, then import style for EACH modules referenced by this block using data-block="xx,yy,zz"
     * if attribute data-style-MODULENAME is present, then import style for THAT module
     * 
     */
    importCss() {
        let that = this;
        var importStyles = [];

        // specific module name
        let asyncStyleSpecific = this.$element.hasAttribute('data-style-' + this.moduleName);
        if(asyncStyleSpecific) {
            importStyles.push(this.moduleName);
        }

        // generic module name
        let asyncStyleGeneric = this.$element.hasAttribute('data-style');
        if(asyncStyleGeneric) {
            importStyles.push(this.moduleName);
        }

        // for each unique item, import style
        let uniqueImportStyle_s = importStyles.filter( (value, index, self) => {   return self.indexOf(value) === index; } );

        // if not empty list of style to import, mark element as css-required
        if(uniqueImportStyle_s.length > 0) {
            that.$element.classList.add('css-required');
        }

        uniqueImportStyle_s.forEach((moduleName) => {
            that.$element.classList.add('css-pending');
            
            // import css
            import( /* webpackPreload: true */ '../blocks/' + moduleName + '/main.scss').then( (css) => {
                // css steps from pending to loaded
                that.$element.classList.add('css-loaded');
                that.$element.classList.remove('css-pending');
            });
        });

    }
}


/**
 * Module to DefaultModule class bridge 
 */
 class ModuleClass extends DefaultModule {
    
    constructor(moduleName, $element) {
        
        // this.moduleName = moduleName;

        super({
            moduleName: moduleName,
            $element: $element
        }); // instance parent

        this.init();
    }

    /**
     * Run right sequence after module load
     */
    init() {
        this.importCss(); // import css module, if necessary (if !isset <style data-style="style-moduleName" /> then import style)
    }
}

export {
    DefaultModule,
    ModuleClass
}
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

// import Swiper JS
import {Swiper, Navigation} from 'swiper';
// import Swiper styles
import 'swiper/css';

import { lerp } from './../../js/math';
import { gsap } from 'gsap';

var transition = [];
var transition_current = [];

Swiper.use([Navigation]);

const MODULE_NAME = 'swipe-cpt';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // enable gsap ticker
        gsap.ticker.add(this.smooth);

        // run module methods
        this.initSwiper();
    },

    // ______________________________________________________________________

    /**
     * Make smooth transition using GSAP
     */
     smooth: function() {

        const that = this;

        if(transition.$el !== undefined) {

            transition_current.x = lerp(transition_current.x || transition.x, transition.x, 0.05);

            // then it translate to lerp value
            if(transition.$el) {
                transition.$el.style.transform = `translate3d(${transition_current.x}px, 0, 0)`; // translate x
            }

        }
    },

    // ______________________________________________________________________ù

    initSwiper() {
        const that = this;

        const swiper = new Swiper(this.module.$element.querySelector('.swiper'), {
            // Optional parameters
            direction: 'horizontal',
            watchSlidesProgress: true,
            // loop: true,
            parallax:true,
            autoHeight: true,

            height: 400,

            resistance: true,           // enable resistance
            resistanceRatio:.95,        // resistance ?

            momentumRatio: 1,           // free mode momentum ratio (distance)
            momentumVelocityRatio: 1,   // free mode momentum (speed)

            speed: 600,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            on: {
                // When progress (0..1) changes, do actions on slides
                progress: function () {
                    let swiper = this;
                    let progress = 0;
                    let innerTranslate = 0; // reset title x translations

                    /**
                     * Parallax
                     * 
                     * CPU intensive
                     * if paralax active, check every progress and update every slide between 0 and 1
                     */
                    if(swiper.params.parallax) {

                        const $headerTitle = that.module.$element.querySelector(".js-header-title");

                        // get progress of first slide
                        if(swiper.slides && swiper.slides.length > 0) {
                            progress = swiper.slides[0].progress || 0;

                            // calculation based on direction
                            innerTranslate = progress * -10; // how much x translation
                        }

                        transition = {
                            $el: $headerTitle,
                            x: innerTranslate
                        };
                    }
                },

                // On breakpoint change, switch swiper behavior
                breakpoint(swiper,breakpointParams) {
                    // on resize, when disable parallax, reset translate to -50%
                    if(swiper.params.parallax === false) {

                    }
                }
            }
         });
    }

};

export default Module;
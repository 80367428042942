"use strict";

import { ModuleClass } from '../../js/DefaultModule';

// import Swiper JS
import Swiper, { Navigation, Thumbs } from 'swiper';
// import Swiper styles
import 'swiper/css';

Swiper.use([Navigation]);

const MODULE_NAME = 'table-conto-termico';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // items
        this.module.$filter_s = this.module.$element.querySelectorAll('.filter');
        this.module.$table = this.module.$element.querySelector('.table');

        // init filter data
        this.module.filters = [];

        // run module methods
        this.initTableFilterData();
        this.initFilterEvents();
    },

    // ______________________________________________________________________

    /**
     * Set table data with all active filters
     */
    initTableFilterData() {

        this.module.$filter_s.forEach(($filter) => {
            let name = $filter.getAttribute('name');

            this.module.filters[name] = $filter.checked;
        });
    },

    // ______________________________________________________________________

    /**
     * Init filter events
     */
    initFilterEvents() {

        // 
        this.module.$filter_s.forEach(($filter) => {
            $filter.addEventListener('change',(e) => {
                let name = e.target.getAttribute('name');

                this.module.filters[name] = e.target.checked;

                // then, trigger manageColumnVisibility
                this.manageColumnVisibility();
            });
        });
    },

    // ______________________________________________________________________

    manageColumnVisibility() {
        let $column_element_s = this.module.$table.querySelectorAll('.column-element');

        $column_element_s.forEach(($el) => {
            let key = $el.getAttribute('data-column');

            if(!this.module.filters[key]) {
                $el.classList.add('d-none');
            } else {
                $el.classList.remove('d-none');
            }
        });
    }

    // ______________________________________________________________________

};

export default Module;
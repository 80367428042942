"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'svg-animated';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.svgInit();
    },

    // ______________________________________________________________________

    /**
     * Init SVG
     */
    svgInit() {

        let that = this;

        // Trigger
        const $cta_1 = this.module.$element.querySelector('.cta_1');

        // on click, disable cta and enable svg
        if($cta_1) {
            $cta_1.addEventListener('click',(e) => {

                e.preventDefault();

                // hide cta
                $cta_1.classList.add('disable');

                var svg_selector = e.target.getAttribute('href');

                var $svg = document.querySelector(svg_selector).querySelector('svg');

                // run sequence
                that.svgRunSequence($svg);
            });
        }

    },

    // ______________________________________________________________________

    /**
     * Run sequence
     */
    svgRunSequence($svg) {

        const that = this;

        // set svg ready to start
        $svg.classList.add('ready');

        // get count transitions
        let transitionCount = parseInt($svg.parentNode.getAttribute('data-transition-count'));

        for(var i = 0; i < transitionCount; i++) {
            let transitionId = $svg.closest('.block-svg-animated').getAttribute('data-transition-id-' + i) ?? false;
            let transitionDelay = $svg.closest('.block-svg-animated').getAttribute('data-transition-delay-' + i) ?? 0;

            that.runElement($svg,transitionId, transitionDelay,"");
        }

    },

    // ______________________________________________________________________

    /**
     * Run a single element of the sequence
     * @param {*} $svg 
     * @param {*} elementSelector 
     * @param {*} time 
     * @param {*} animated 
     */
    runElement($svg, elementSelector, time, animated)
    {
        setTimeout(function(){
            var $el = $svg.querySelector(elementSelector);

            if($el) {
                $el.classList.add('active');
            }
        },time);
    }

    // ______________________________________________________________________

};

export default Module;
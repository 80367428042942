"use strict";

import {ModuleClass} from '../../js/DefaultModule';

// just import (auto load?)
import MatchHeight from 'matchheight';

const MODULE_NAME = 'match-height';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        // no modules, MatchHeight auto loads
    },

    // ______________________________________________________________________

};

export default Module;
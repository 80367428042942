"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'collapse-at-least-one-opened';

const Module = {

    $element: null,
    lastIdOpened: false,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.atLeastOneOpen()
    },

    // ______________________________________________________________________

    /**
     * At least one element is open
     * (if current element is still open, skip)
     */
    atLeastOneOpen() {
        const that = this;

        let $collapsible_s = this.module.$element.querySelectorAll('.collapse');

        $collapsible_s.forEach(($collapsible) => {
            $collapsible.addEventListener("hide.bs.collapse", (e) => {

                if(that.lastIdOpened == e.target.id) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                
            });

            $collapsible.addEventListener("show.bs.collapse", (e) => {
                that.lastIdOpened = e.target.id;
            });
        });

    }

};

export default Module;
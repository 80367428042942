"use strict";

// import Swiper JS
import {Swiper, Navigation} from 'swiper';
// import Swiper styles
import 'swiper/css';

import { lerp } from './../../../../js/math';
import { gsap } from 'gsap';

var transition = [];
var transition_current = [];

Swiper.use([Navigation]);

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($module) {

        this.module = $module;

        // enable gsap ticker
        // gsap.ticker.add(this.smooth);

        // run module methods
        this.initSwiper();

    },

    // ______________________________________________________________________

    /**
     * Make smooth transition using GSAP
     */
     smooth: function() {

        const that = this;

        if(transition.$el !== undefined) {

            transition_current.x = lerp(transition_current.x || transition.x, transition.x, 0.05);

            // then it translate to lerp value
            if(transition.$el) {
                transition.$el.style.transform = `translate3d(${transition_current.x}px, 0, 0)`; // translate x
            }

        }
    },

    // ______________________________________________________________________

    initSwiper() {
        const that = this;

        const swiper = new Swiper(this.module.$element.querySelector('.swiper'), {
            // Optional parameters
            direction: 'horizontal',
            watchSlidesProgress: true,
            // loop: true,
            parallax:true,
            autoHeight: false,

            resistance: true,           // enable resistance
            resistanceRatio:.95,        // resistance ?

            momentumRatio: 1,           // free mode momentum ratio (distance)
            momentumVelocityRatio: 1,   // free mode momentum (speed)

            speed: 600,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

    }

};

export default Module;
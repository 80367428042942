"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'archive-categories';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        // ...
    },

    // ______________________________________________________________________

};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'video';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        this.$element = $element;

        // temp remove Controls
        this.temporarilyDisableControls();

        // run module methods
        this.enablePlay();
    },

    // ______________________________________________________________________

    /**
     * If controls are enabled, then temporarily remove them
     */
    temporarilyDisableControls() {
        var $video = this.$element.querySelector('video');

        this.hasControls = $video.hasAttribute('controls');

        if(this.hasControls) {
            $video.removeAttribute('controls')
        }
    },

    // ______________________________________________________________________

    enablePlay() {

        var that = this;
        var $play = this.$element.querySelector('.controls').querySelector('.play');
        $play.classList.remove('disabled');

        // Enable click event
        $play.addEventListener('click',function() {

            var $element = this.closest('.block-video');
            var $video = $element.querySelector('video');

            // plays video
            $video.play();
    
            // disable play button
            this.classList.add('disabled');
    
            // re-enables controls, if availables
            $video.setAttribute('controls',true);
        });
    },

    // ______________________________________________________________________

};

export default Module;
"use strict";

import { ModuleClass } from '../../js/DefaultModule';

// import Swiper JS
import Swiper, { Navigation } from 'swiper';
// import Swiper styles
import 'swiper/css';

Swiper.use([Navigation]);

const MODULE_NAME = 'swipe-gallery-base-1';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.initSwiper();
    },

    // ______________________________________________________________________

    initSwiper() {
        const that = this;

        // get config
        let slidesPerViewXs = this.module.$element.getAttribute('data-slidesPerView-xs') || 1;
        let slidesPerViewMd = this.module.$element.getAttribute('data-slidesPerView-md') || 1;
        let slidesPerViewLg = this.module.$element.getAttribute('data-slidesPerView-lg') || 1;
        let slidesPerViewXl = this.module.$element.getAttribute('data-slidesPerView-xl') || 1;
        let slidesPerViewXxl = this.module.$element.getAttribute('data-slidesPerView-xxl') || 1;

        let spaceBetweenXs = this.module.$element.getAttribute('data-spaceBetween-xs') || 0;
        let spaceBetweenMd = this.module.$element.getAttribute('data-spaceBetween-md') || 0;
        let spaceBetweenLg = this.module.$element.getAttribute('data-spaceBetween-lg') || 0;
        let spaceBetweenXl = this.module.$element.getAttribute('data-spaceBetween-xl') || 0;
        let spaceBetweenXxl = this.module.$element.getAttribute('data-spaceBetween-xxl') || 0;

        let swiperConfiguration = {
            slidesPerView: parseFloat(slidesPerViewXs),
            spaceBetween: parseInt(spaceBetweenXs),
            // slidesOffsetBefore: - parseInt(spaceBetweenXs),

            // Optional parameters
            direction: 'horizontal',
            watchSlidesProgress: true,
            // loop: true,
            parallax: true,
            autoHeight: true,

            centerInsufficientSlides: true,

            preloadImages: true,

            resistance: true,           // enable resistance
            resistanceRatio: .95,        // resistance ?

            momentumRatio: 1,           // free mode momentum ratio (distance)
            momentumVelocityRatio: 1,   // free mode momentum (speed)

            speed: 600,

            breakpoints: {
                768: {
                    slidesPerView: parseFloat(slidesPerViewMd),
                    spaceBetween: parseInt(spaceBetweenMd),
                    // slidesOffsetBefore: - parseInt(spaceBetweenMd)
                },
                992: {
                    slidesPerView: parseFloat(slidesPerViewLg),
                    spaceBetween: parseInt(spaceBetweenLg),
                    // slidesOffsetBefore: - parseInt(spaceBetweenLg)
                },
                1200: {
                    slidesPerView: parseFloat(slidesPerViewXl),
                    spaceBetween: parseInt(spaceBetweenXl),
                    // slidesOffsetBefore: - parseInt(spaceBetweenXl)
                },
                1400: {
                    slidesPerView: parseFloat(slidesPerViewXxl),
                    spaceBetween: parseInt(spaceBetweenXxl),
                    // slidesOffsetBefore: - parseInt(spaceBetweenXxl)
                }
            },

            navigation: {
                nextEl: this.module.$element.querySelector('.swiper-button-next'),
                prevEl: this.module.$element.querySelector('.swiper-button-prev')
            },

            on: {
                init: function () {
                  // trigger window resize to force swiper height
                  window.dispatchEvent(new Event('resize'));
                },
            },

        };

        // init swiper
        const swiper = new Swiper(this.module.$element.querySelector('.swiper'), swiperConfiguration);

        // on swiper inited, set module as .complete
        swiper.once('progress',(e) => {
            that.module.$element.classList.add('ready');
        });

    }

    // ______________________________________________________________________

};

export default Module;
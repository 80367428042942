"use strict";

import {ModuleClass} from '../../js/DefaultModule';

import $ from "jquery";

const MODULE_NAME = 'jquery';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // se as global
        window.jQuery = $;
        window.$ = $;
    },

    // ______________________________________________________________________

};

export default Module;
"use strict";

class EventRelationClass {

    // ______________________________________________________________________

    /**
     * Create a "related event".
     * 
     * Example:
     * Navbar anchor could be related to other element in the page.
     * Click on
     * <a href="#products" rel="subnavbar" />
     * Will send event "clickRelation" to #subnavbar element.
     * 
     * 
     * @param {*} args 
     * @returns 
     */
    createEvent(args) {

        let config = {
            event: args.event || 'click',
            $parentElement: args.$parentElement || null,
            itemSelector: args.itemSelector || 'a',
            hrefAttribute: args.hrefAttribute || 'href',
            relAttribute: args.relAttribute || 'rel'
        }

        // ________

        // Check config

        // do nothing when config is incomplete
        if (!this._isElement(config.$parentElement)) return;

        // ________

        // Set related event
        this._setEvent(config);

        // ________

        // Set dismiss event clicking on dismiss element
        this._dismissEvent(config);
    }

    // ______________________________________________________________________

    _dismissEvent(config) {

        document.body.addEventListener('click', (e) => {
            
            let dismissRelatedEvent = new Event('DismissEventRelation');

            // Send dismiss event
            document.body.dispatchEvent(dismissRelatedEvent);
        })

    }

    // ______________________________________________________________________

    /**
     * Create custom related event
     * @param {*} config 
     */
    _setEvent(config) {

        config.$parentElement.addEventListener(config.event, (e) => {

            e.stopPropagation(); // let event outside the clicked element

            let target = e.target;

            if (target.matches(config.itemSelector) || target.closest(config.itemSelector) ) { // evaulate also anchestor

                // is target clicked, or has anchestor?
                let $clickedElement = target.matches(config.itemSelector) ? target : target.closest(config.itemSelector);

                let href = $clickedElement.getAttribute(config.hrefAttribute);
                let rel = $clickedElement.getAttribute(config.relAttribute); // is used to send events to

                // Note: text passed by config.relAttribute is the ID of the $clickedElement of the relation, without #
                const $relatedElement = document.querySelector('#' + rel);

                // if click is on anchor and relation element exists, then dispatch clickRelation event on related element
                if ($relatedElement && href.charAt(0) === "#") {

                    let relatedEvent = new CustomEvent('EventRelation', {
                        detail: {
                            href: href,
                            initiator: $clickedElement
                        }
                    });

                    // Send custom event with details.href
                    $relatedElement.dispatchEvent(relatedEvent);
                }
            }
        });
    }

    // ______________________________________________________________________

    /**
     * Add a listener to the proprietary "EventRelation" event and pass the event object to the $element.
     * 
     * Note: the original event is a CustomEvent, filled with e.detail.
     * Therefore the callback can use e.detail to extract given parameters.
     * 
     * @param {*} args 
     * @returns 
     */
    addListener(args) {

        let config = {
            $element: args.$element || null,            // element who listen to event
            callback: args.callback || function () { },   // callback function
            dismissCallback: args.dismissCallback || function () { }   // callback function
        }

        // ________

        // Check config

        // do nothing when config is incomplete
        if (!this._isElement(config.$element)) return;

        // ________

        // create event listener and call the callback
        config.$element.addEventListener('EventRelation', (e) => {
            config.callback(e);
        });

        // create dismiss event listener and call the callback
        if(!document.body.hasAttribute('data-eventListener-DismissEventRelation')) { // add event listener only once
            document.body.addEventListener('DismissEventRelation', (e) => {
                // mark element as it yet with event listener
                document.body.setAttribute('data-eventListener-DismissEventRelation',true);
    
                // run dismiss callback
                config.dismissCallback(e);
            });
        }

        // prevent event propagation on this element, to allow (for example) click outside
        config.$element.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        return config;

    }

    // ______________________________________________________________________

    /**
     * Returns if obj is instance of HTMLElement
     * 
     * @param {*} obj 
     * @returns 
     */
    _isElement(obj) {
        try {
            //Using W3 DOM2 (works for FF, Opera and Chrome)
            return obj instanceof HTMLElement;
        }
        catch (e) {
            //Browsers not supporting W3 DOM2 don't have HTMLElement and
            //an exception is thrown and we end up here. Testing some
            //properties that all elements have (works on IE7)
            return (typeof obj === "object") &&
                (obj.nodeType === 1) && (typeof obj.style === "object") &&
                (typeof obj.ownerDocument === "object");
        }
    }

}

const EventRelation = new EventRelationClass();

export {
    EventRelation
}
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'main';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // Set body fixed when element is show.bs.collapse
        this.bodyFixedOnBsCollapse();
    },

    // ______________________________________________________________________

    bodyFixedOnBsCollapse() {

        // when navigation mobile is visible, prevent body scroll
        document.body.addEventListener('navigation-mobile-visible',(e) => {
            // document.body.classList.add('position-fixed');
            document.body.classList.add('overflow-hidden');
        });

        // when navigation mobile is visible, re-enable body scroll
        document.body.addEventListener('navigation-mobile-hidden',(e) => {
            // document.body.classList.remove('position-fixed');
            document.body.classList.remove('overflow-hidden');
        });
    },

    // ______________________________________________________________________


};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

import 'iframe-lightbox';

const MODULE_NAME = 'iframe-lightbox';

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        [].forEach.call(this.module.$element.getElementsByClassName("iframe-lightbox-link"), function (el) {
            el.lightbox = new IframeLightbox(el);
        });
    },

    // ______________________________________________________________________

};

export default Module;
"use strict";

import { ModuleClass } from '../../js/DefaultModule';

// import Swiper JS
import Swiper, {Navigation, FreeMode} from 'swiper';
// import Swiper styles
import 'swiper/css';

import { lerp } from './../../js/math';
// import { gsap } from 'gsap';

import {TimelineMax,TweenMax, Power4, gsap} from "gsap";  // Also works with TweenLite and TimelineLite: import { TweenMax, TimelineMax } from "gsap";
gsap.config({nullTargetWarn:false});

const MODULE_NAME = 'swipe-tile';

var transition_s = [];
var transition_s_current = [];

Swiper.use([Navigation, FreeMode]);

const Module = {

    $element: null,

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // enable gsap ticker
        gsap.ticker.add(this.smooth);

        // run module methods
        this.init_swiper();
    },

    // ______________________________________________________________________

    /**
     * Make smooth transition using GSAP
     */
    smooth: function() {

        const that = this;
        transition_s.forEach((transition, index) => {

            // init temp
            transition_s_current[index] = transition_s_current[index] || [];
            transition_s_current[index].x = transition_s_current[index].x || transition.x;

            // fix weird jump: when x = 0 it makes a jump
            if(transition.x == 0) transition.x = 1;

            // If transition is a jump from 2 far object, maybe is a jump caused by loop reset of the element
            if( Math.abs(transition.x - transition_s_current[index].x) > 70) {
                // reset item and allow jump without lerp transition
                transition_s_current[index].x = transition.x;
            } else {
                // smooth transition with lerp
                // then it lerps medium value between desidered and previous
                transition_s_current[index].x = lerp(transition_s_current[index].x, transition.x, 0.1);
                transition_s_current[index].x = Math.trunc(transition_s_current[index].x);
            }
            
            // if( (transition_s_current[index].x > 1) ) transition_s_current[index].x = 0;

            // OLD METHOD (style.transform) - 2021
            // isseue: not smooth
            // then it translate to lerp value
            // transition.$el.style.transform = `translate3d(${transition_s_current[index].x}px, 0, 0)`; // translate x

            // NEW METHOD - Novembre 2022
            TweenMax.to(transition.$el, 0, {
                x: `${transition_s_current[index].x}px`,
                transformOrigin:"0% 100%"
            });

        })
    },

    // ______________________________________________________________________

    init_swiper() {

        const that = this;

        const swiper = new Swiper(this.module.$element.querySelector('.swiper'), {
            // Optional parameters
            direction: 'horizontal',
            watchSlidesProgress: true,

            autoHeight: true, //enable auto height

            // lazy
            preloadImages: false,
            lazy: true,
            loadingClass: 'loading',
            loadedClass: 'loaded',
            checkInView: true,
            
            // loop: true,
            // loopAdditionalSlides:this.module.$element.querySelectorAll('.swiper .swiper-slide').length * 2, // double length of total slides

            slidesPerView: 1.5,         // default: 1.5 slides

            resistance: true,           // enable resistance
            resistanceRatio:.95,        // resistance ?

            spaceBetween: 15,           // space between slides
            slidesOffsetBefore:15,      // space before first slide

            momentumRatio: 1,           // free mode momentum ratio (distance)
            momentumVelocityRatio: 1,   // free mode momentum (speed)

            speed: 600,

            parallax:false,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            breakpoints: {
                768: {
                    freeMode: true,
                    slidesOffsetBefore:0,
                    parallax:true,
                    slidesPerView: 2,
                },
                992: {
                    freeMode: true,
                    slidesOffsetBefore:0,
                    parallax:true,
                    slidesPerView: 3
                }
            },
            
            grabCursor: true,

            on: {
                // When progress (0..1) changes, do actions on slides
                progress: function () {
                    let swiper = this;

                    /**
                     * Parallax
                     * 
                     * CPU intensive
                     * if paralax active, check every progress and update every slide between 0 and 1
                     */
                    if(swiper.params.parallax) {

                        for (let i = 0; i < swiper.slides.length; i++) {
                            
                            // progress
                            let progress = swiper.slides[i].progress;
                            let innerTranslate = (progress) * 100; // how much x transation

                            let $image_s = swiper.slides[i].querySelectorAll(".swiper-slide__image:not(.d-block.d-md-none)"); // get only md+ images
                            $image_s.forEach(($image) => {
                                transition_s[i] = {
                                    i: i,
                                    $el: $image,
                                    x: innerTranslate
                                }
                            });

                        }
                    }
                },

                // On breakpoint change, switch swiper behavior
                breakpoint(swiper,breapointParams) {
                    // on resize, when disable parallax, reset translate to -50%
                    if(swiper.params.parallax === false) {
                        for (let i = 0; i < swiper.slides.length; i++) {
                            let $image_s = swiper.slides[i].querySelectorAll(".swiper-slide__image");
                            $image_s.forEach(($image) => {
                                $image.style.transform = "translate3d(-50%, 0, 0)";
                            });
                        }
                    }
                }
            }

        });
    }

};

export default Module;
"use strict";

import {ModuleClass} from '../../js/DefaultModule';

const MODULE_NAME = 'technology-filtered';

const Module = {

    $element: null,
    activeFilters: [],

    // ______________________________________________________________________

    /**
     * Init current module
     * @param {*} $element 
     */
    init($element) {

        // run module common methods
        this.module = new ModuleClass(MODULE_NAME, $element);

        // run module methods
        this.init_filters();
    },

    // ______________________________________________________________________

    init_filters() {
        const that = this;
        const $filterTerm_s = this.module.$element.querySelectorAll('.taxonomy-filter .taxonomy-filter-term');

        $filterTerm_s.forEach(($filterTerm) => {

            $filterTerm.addEventListener('change', (e) => {
                let chosen_taxonomy = e.target.getAttribute('data-taxonomy');
                let chosen_term = e.target.getAttribute('data-term');

                // remove previous value
                if(that.activeFilters[chosen_taxonomy]) {
                    delete that.activeFilters[chosen_taxonomy];
                };

                // set new value
                if(chosen_term !== '') {
                    that.activeFilters[chosen_taxonomy] = chosen_term;
                }
                
                // then update results, list visibility
                that.update_results();
                that.update_list();
                that.update_filter_plus_by_fuel();
                that.update_filter_length_visibility();

                // update current even
                // hidden elements are excluded
                that.update_current_even();
            });

        });
    },

    // ______________________________________________________________________

    update_results() {
        const that = this;
        const $item_s = this.module.$element.querySelectorAll('.technology-results .item');

        // cycle all results
        $item_s.forEach(($item) => {

            let found = true; // default all visible

            // if at least 1 filter active
            if(JSON.stringify(that.activeFilters.length) !== '{}') {

                for (var taxonomy in that.activeFilters) {
                    let term = that.activeFilters[taxonomy];
                    if($item.hasAttribute('data-' + taxonomy + '-' + term)) {
                        found = found && true;
                    } else {
                        found = found && false;
                    }
                }
            }

            // on found, remove d-none, else add d-none
            if(found) {
                $item.classList.remove('d-none');
            } else {
                $item.classList.add('d-none');
            }

        });
    },

    // ______________________________________________________________________

    update_list() {
        const that = this;
        const $item_s = this.module.$element.querySelectorAll('.technology-list .item');

        const taxonomy = 'fuel';

        // cycle all results
        $item_s.forEach(($item) => {

            let found = true; // default all visible

            // if at least 1 filter active
            if(JSON.stringify(that.activeFilters.length) !== '{}') {

                let term = that.activeFilters[taxonomy];

                if(term !== undefined) {
                    if($item.hasAttribute('data-' + taxonomy + '-' + term)) {
                        found = found && true;
                    } else {
                        found = found && false;
                    }
                }
            }

            // on found, remove d-none, else add d-none
            if(found) {
                $item.classList.remove('d-none');
            } else {
                $item.classList.add('d-none');
            }

        });
    },

    // ______________________________________________________________________

    update_filter_plus_by_fuel() {
        const that = this;
        const $item_s = this.module.$element.querySelectorAll('.technology-filters form[data-filter="plus"] .item');

        const taxonomy = 'fuel';

        // cycle all results
        $item_s.forEach(($item) => {

            let found = true; // default all visible

            // if at least 1 filter active
            if(JSON.stringify(that.activeFilters.length) !== '{}') {
                let term = that.activeFilters[taxonomy];

                if(term !== undefined) {
                    if($item.hasAttribute('data-' + taxonomy + '-' + term)) {
                        found = found && true;
                    } else {
                        found = found && false;
                    }
                }
            }

            // on found, remove d-none, else add d-none
            if(found) {
                $item.classList.remove('d-none');
            } else {
                $item.classList.add('d-none');
            }

        });
    },

    // ______________________________________________________________________

    /**
     * Update visibility of elements by lenght
     */
    update_filter_length_visibility() {
        const that = this;
        const $form = this.module.$element.querySelector('.technology-filters form[data-filter="plus"]');
        const $item_s = $form.querySelectorAll('.item:not(.d-none)'); // all collapsed items, not d-none due to other filters

        // limit
        const limit = $form.getAttribute('data-limit');

        $item_s.forEach(($item, index) => {
            console.log(index);
            if(index > limit - 1) {
                $item.classList.add('collapse');
            } else {
                $item.classList.remove('collapse');
            }
        })
    },

    // ______________________________________________________________________

    update_current_even() {
        const that = this;
        const $item_s = this.module.$element.querySelectorAll('.technology-results .item:not(.d-none)');

        // cycle all results
        $item_s.forEach(($item, index) => {

            $item.classList.remove('current-even');

            if(index%2===1) {
                $item.classList.add('current-even');
            }
        });
    }

    // ______________________________________________________________________

    /*

    update_results() {
        const that = this;
        const $item_s = this.module.$element.querySelectorAll('.technology-results .item');

        // cycle all results
        $item_s.forEach(($item) => {

            let found = true; // default all visible

            // if at least 1 filter active
            if(JSON.stringify(that.activeFilters.length) !== '{}') {

                for (var taxonomy in that.activeFilters) {
                    let term = that.activeFilters[taxonomy];
                    if($item.hasAttribute('data-' + taxonomy + '-' + term)) {
                        found = found && true;
                    } else {
                        found = found && false;
                    }
                }
            }

            // on found, remove d-none, else add d-none
            if(found) {
                $item.classList.remove('d-none');
            } else {
                $item.classList.add('d-none');
            }

        });
    },

    */

};

export default Module;